.table-section{
  .table-block{
    background-color: #fff;
    border: 1px solid #E2E8F0;
    box-shadow: 0 0 50px 0 rgba(0,0,0,.1);
    border-radius: 10px;
    padding: 10px;
    table{
      width: 100%;
      thead{
        tr{
          th{
            padding: 0;
            .content-th {
              padding: 5px 10px;
              .name-column{
                margin-bottom: 5px;
                font-size: 16px;
                font-weight: 500;
                color: #383836;
              }
              .search-column{
                margin-bottom: 5px;
              }
              input{
                border: 2px solid #d2d2d2;
                border-radius: 16px;
                padding: 10px 10px;
                font-size: 12px;
                line-height: 1;
                display: block;
                width: 100%;
                &:focus{
                  border: 2px solid #6f42c1;
                }

              }
              .select{
                width: 100%;
                height: 38px;
                border: 2px solid #d2d2d2;
                border-radius: 16px;
                margin-bottom: 5px;
                position: relative;
                display: flex;
                align-items: center;
                padding: 5px;
                .select-value{
                  flex-grow: 1;
                  max-width: 100%;
                }
                .select-icon{
                  width: 26px;
                  height: 26px;
                  border-radius: 50%;
                  flex-shrink: 0;
                  position: relative;
                  cursor: pointer;
                  &:hover{
                    background-color: #efefff;
                  }
                  &::before{
                    content: '';
                    position: absolute;
                    display: block;
                    width: 8px;
                    height: 8px;
                    border:2px solid #000;
                    border-top: none;
                    border-left:none;
                    transform: rotate(45deg);
                    left: calc(50% - 4px);
                    top: calc(50% - 6px);
                  }
                }
                .select-list{
                  display: none;
                }
                &.select-open{
                  border-radius: 16px 16px 0 0;
                  border-color: #6f42c1;
                  .select-icon{
                    &::before{
                      transform: rotate(225deg);
                      top: calc(50% - 2px);
                    }
                  }

                  .select-list{
                    position: absolute;
                    display: block;
                    background-color: #fff;
                    border: 2px solid #6f42c1;
                    top: 100%;
                    width: calc(100% + 4px);
                    z-index: 5;
                    left: -2px;
                    border-radius: 0 0 16px 16px;
                    overflow: hidden;
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
                    ul{
                      li{
                        padding: 8px 10px;
                        font-weight: 400;
                        background-color: #fff;
                        cursor: pointer;
                        font-size: 12px;
                        &:hover{
                          background-color: #efefff;
                        }
                      }
                    }
                  }
                }
              }
            }
            &:nth-child(1){
              width: 1%;
              min-width: 25px;
            }
            &:nth-child(2){

            }
            &:nth-child(3){
              width: 13.1%;
            }
            &:nth-child(4){
              width: 10%;
              min-width: 140px;
            }
            &:nth-child(5){
              width: 10%;
              min-width: 140px;
            }
            &:nth-child(6){
              width: 3.275%;
            }
            &:nth-child(7){
              width: 3.275%;
            }
          }
        }
      }
      tbody{
        tr{
          padding: 10px;
          &.group-properties{
            td{
              .content-td{
                padding: 10px 10px;
                font-weight: 500;
                font-size: 16px;
                line-height: 1;
                background-color: rgba(102, 102, 255, 0.1);
              }
            }
          }
          &.property{
            td{
              .content-td{
                padding:5px 10px;
                font-size: 14px ;
                color: #5e5c5c;
                font-weight: 500;
              }
            }
            .property-checkbox{
              display: flex;
              align-items: center;

              label{
                overflow: hidden;
                border: 2px solid #ababa6;
                width: 18px;
                height: 18px;
                border-radius: 4px;
                position: relative;
                cursor: pointer;
                left: -5px;
                input{
                  z-index: 1;
                  left: -999px;
                  opacity: 0;
                  border: none;
                  position: absolute;
                }
                &::before{
                  content: '';
                  position: absolute;
                  border: 2px solid #fff;
                  width: 6px;
                  height: 9px;
                  border-top: none;
                  border-left: none;
                  transform: rotate(45deg);
                  left: 4px;
                  top: 1px;
                }
                &.checked{
                  background-color: #6f42c1;
                  border-color: #6f42c1;


                }
              }
            }
            .property-icon-num-name{
              display: flex;
              align-items: center;
              .property-icon{
                width: 26px;
                height: 26px;
                margin-right: 10px;
                img{
                  width: 100%;
                  object-fit: contain;
                }
              }
              .separator{
                margin: 0 5px;
              }
            }
            .property-status{
              height: 38px;
              width: 100%;
              border-radius: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #000000;
              font-weight: 400;
              &.property-status-new{
                background-color: #e5e5e5;
              }
              &.property-status-work{
                background-color: #CCCCFC;
              }
              &.property-status-agreed{
                background-color: #D7F2DE;
              }
              &.property-status-not{
                background-color: #F5D4E2;
              }
            }
            .property-count{
              .value-1{

              }
              .value-2{

              }
            }
          }
          &:hover{
            background-color: #fcfcfc;
          }
        }
      }
    }
  }
  .list-props{
    width: 350px;
    padding-left: 20px;
    ul{
      li{
        margin-bottom: 10px;
        .text{
          color: #848484;
          font-size: 16px;
          line-height: 19px;
        }
        &.parent{
          position: relative;
          cursor: pointer;
          margin-bottom: 10px;
          &>.text{
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #333333;
          }

          .child-list{
            display: none;
            padding: 10px 0 10px 20px;
            ul{
              li{
                margin-bottom: 0px;
              }
            }
          }
          &.parent-open{
              .child-list{
                display: block;
              }
          }
        }
        &:hover{
          &>.text{
            color: #6f42c1;
          }
        }
      }
    }
    .list-control{
      margin-top: 40px;
      .btn{
        height: 45px;
        font-size: 16px;
        line-height: 30px;
        font-weight: 500;
        width: calc(100% - 15px);
      }
    }
  }
}