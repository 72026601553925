.publications-page{
  .publications-list{
    .publication{
      display: flex;
      margin-bottom: 40px;
      .publication-img{
        margin-right: 40px;
        width: 197px;
        height: 278px;
        flex-shrink: 0;
        img{
          display: block;
          width: 100%;
          height: auto;
          object-fit: cover;
          object-position: top;
        }
      }
      .publication-info{
        .block-sm{
          display: contents;
        }
        .name{
          font-size: 28px;
          line-height: 32px;
          margin-bottom: 30px;
          font-weight: 700;

        }
        .value{
          font-size: 20px;
          line-height: 30px;
        }
        .text{
          font-size: 20px;
          line-height: 30px;
          font-weight: 700;
        }
        .link{
          color: #3072C4;
          font-size: 20px;
          line-height: 30px;
        }

      }
    }
  }
}

@media screen and (max-width: 1199px){
  .publications-page{
    .publications-list{
      .publication{
        .publication-info{
          .name{
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 15px;
          }
          .value{
            font-size: 18px;
            line-height: 26px;
          }
          .text{
            font-size: 18px;
            line-height: 26px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 991px){
  .publications-page{
    .publications-list{
      .publication{
        margin-bottom: 20px;
        .publication-img{
          margin-right: 15px;
        }
        .publication-info{
          .name{
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 15px;
          }
          .value{
            font-size: 16px;
            line-height: 20px;
          }
          .text{
            font-size: 16px;
            line-height: 20px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px){
  .publications-page{
    .publications-list{
      .publication{
        margin-bottom: 30px;
        flex-wrap: wrap;
        .publication-img{
          margin-bottom: 30px;
        }
        .publication-info{
          display: contents;
          .block-sm{
            display: block;
            width: calc(100% - 220px);
          }
          .name{
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 15px;
          }
          .value{
            font-size: 16px;
            line-height: 20px;
          }
          .text{
            width: 100%;
            font-size: 16px;
            line-height: 20px;
            font-weight: 700;
          }
          .date{
            width: 100%;
          }
          .link{
            width: 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 575px){
  .publications-page{
    .publications-list{
      .publication{
        margin-bottom: 30px;
        flex-wrap: wrap;
        .publication-img{
          width: 34.26vw;
          height: auto;
          margin-bottom: 20px;
        }
        .publication-info{
          .block-sm{
            width: calc(100% - 24px - 34.26vw);
          }
          .name{
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 10px;
          }
          .value{
            font-size: 14px;
            line-height: 16px;
          }
          .text{
            font-size: 14px;
            line-height: 16px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 399px){
  .publications-page {
    .publications-list {
      .publication {
        .publication-img{
          width: 25vw;
          height: auto;
          margin-bottom: 10px;
        }
        .publication-info {
          .block-sm {
            width: calc(100% - 24px - 25vw);
          }
          .name{
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 10px;
          }
          .autors{
            font-size: 12px;
            line-height: 14px;
          }
          .link{
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
  }
}