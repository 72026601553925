body {
  background-color: #FBF9FD;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}
body h1 {
  color: #383836;
  font-size: 60px;
  line-height: 85px;
  margin-bottom: 40px;
  font-weight: 700;
}
body .wrapper {
  margin-top: 100px;
  padding: 50px 0;
}
body * {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  position: relative;
}
body ul, body ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
body a {
  color: #333;
  text-decoration: none;
}
body input {
  outline: none;
}
body.open-mobile-menu {
  overflow: hidden;
}
body.open-mobile-menu header {
  border-bottom: 1px solid #212831;
}
body.open-mobile-menu header .btn-mobile-menu {
  border-color: #6666FF;
  background-color: #6666FF;
}
body.open-mobile-menu header .btn-mobile-menu .text-menu {
  color: #fff;
}
body.open-mobile-menu header .btn-mobile-menu .icon-menu .icon-block:nth-child(2) {
  opacity: 0;
  margin: 0;
}
body.open-mobile-menu header .btn-mobile-menu .icon-menu .icon-block:nth-child(1) {
  position: relative;
  top: 2px;
  transform-origin: 50% 50%;
  transform: rotate(-225deg);
  background-color: #fff;
}
body.open-mobile-menu header .btn-mobile-menu .icon-menu .icon-block:nth-child(3) {
  position: relative;
  top: -2px;
  transform-origin: 50% 50%;
  transform: rotate(225deg);
  background-color: #fff;
}
body.open-mobile-menu header .container-mobile-menu {
  left: 0;
}
body.open-mobile-menu header .container-mobile-menu .mobile-menu::before {
  opacity: 1;
  transition: opacity ease 0.5s 0.5s;
}
body.main-page .wrapper {
  padding: 0 0px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 247px;
  height: 55px;
  border-radius: 100px;
  background-color: #6666ff;
  font-weight: 500;
  font-size: 24px;
  color: #fff;
}
.btn:hover {
  background-color: #6666ff;
  color: #fff;
}

@media screen and (max-width: 1199px) {
  .btn {
    width: 200px;
    height: 45px;
    font-size: 20px;
  }

  body h1 {
    color: #383836;
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 40px;
    font-weight: 700;
  }
  body .wrapper {
    margin-top: 80px;
  }
}
@media screen and (max-width: 991px) {
  body h1 {
    color: #383836;
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 20px;
  }
  body .wrapper {
    margin-top: 60px;
  }
}
@media screen and (max-width: 575px) {
  .btn {
    width: 100%;
    height: 45px;
    font-size: 20px;
  }

  body h1 {
    color: #383836;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  body .wrapper {
    margin-top: 50px;
  }
}
header {
  background-color: #212831;
  position: fixed;
  z-index: 100;
  height: 100px;
  width: 100%;
  border-bottom: 1px solid #909498;
}
header .container-fluid {
  margin: 0 5vw;
  width: calc(100% - 10vw);
  height: 100%;
}
header .container-fluid .row {
  height: inherit;
}
header .logo {
  width: 123px;
  height: 64px;
  display: block;
}
header .logo img {
  width: inherit;
  height: inherit;
  object-fit: contain;
  object-position: center;
  display: block;
}
header .top-menu {
  height: 100px;
}
header .top-menu ul {
  height: inherit;
  display: flex;
  align-items: center;
}
header .top-menu ul li {
  height: inherit;
}
header .top-menu ul li a {
  padding: 0 3.2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  transition: ease 0.4s;
}
header .top-menu ul li a:hover {
  background-color: #6666FF;
}
header .btn-mobile-menu {
  cursor: pointer;
  height: 60px;
  transition: ease 0.4s;
  display: flex;
  align-items: center;
  padding: 0 12px 0 24px;
  margin-right: -12px;
}
header .btn-mobile-menu .text-menu {
  line-height: 1;
  font-size: 12px;
  color: #909498;
  font-weight: 700;
  margin: 0 12px 0 0;
  transition: ease 0.4s;
}
header .btn-mobile-menu .icon-menu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
header .btn-mobile-menu .icon-menu .icon-block {
  width: 20px;
  height: 2px;
  background-color: #909498;
  transition: ease 0.4s;
}
header .btn-mobile-menu .icon-menu .icon-block:nth-child(2) {
  margin: 4px 0;
}
header .btn-mobile-menu:hover {
  border-color: #6666FF;
}
header .btn-mobile-menu:hover .text-menu {
  color: #6666FF;
}
header .btn-mobile-menu:hover .icon-menu .icon-block {
  background-color: #6666FF;
}
header .container-mobile-menu {
  z-index: 900;
  width: 100vw;
  height: calc(100dvh - 49px);
  top: 0px;
  left: 200vw;
  transition: left ease 0.4s;
}
header .container-mobile-menu .mobile-menu {
  position: relative;
  width: inherit;
  height: inherit;
}
header .container-mobile-menu .mobile-menu .menu {
  background-color: #212831;
  width: 260px;
  height: 100%;
  position: absolute;
  top: 1px;
  right: 0;
}
header .container-mobile-menu .mobile-menu .menu .hidden-block {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
header .container-mobile-menu .mobile-menu .menu .hidden-block .scroll-block {
  width: calc(100% + 50px);
  height: calc(100% - 70px);
  overflow: auto;
}
header .container-mobile-menu .mobile-menu .menu .hidden-block .scroll-block .lists .list {
  margin-bottom: 24px;
}
header .container-mobile-menu .mobile-menu .menu .hidden-block .scroll-block .lists .list .content-list ul li a {
  display: block;
  font-size: 14px;
  color: #909498;
  transition: ease 0.4s;
  font-weight: 500;
  text-transform: uppercase;
  padding: 15px 24px;
}
header .container-mobile-menu .mobile-menu .menu .hidden-block .scroll-block .lists .list .content-list ul li a:hover {
  color: #fff;
  background-color: #6666ff;
}
header .container-mobile-menu .mobile-menu .menu .hidden-block .control-block {
  height: 60px;
  position: relative;
  display: flex;
  border-top: 1px solid #909498;
}
header .container-mobile-menu .mobile-menu .menu .hidden-block .control-block .btn {
  padding: 0;
  display: flex;
  width: 50%;
  height: inherit;
  background-color: transparent;
  color: #909498;
  font-weight: 700;
  text-transform: uppercase;
  transition: ease 0.4s;
  font-size: 12px;
  border-radius: 0;
  align-items: center;
  justify-content: center;
}
header .container-mobile-menu .mobile-menu .menu .hidden-block .control-block .btn:hover {
  background-color: #6666ff;
  color: #fff;
}
header .container-mobile-menu .mobile-menu .menu .hidden-block .control-block::before {
  content: "";
  position: absolute;
  display: block;
  width: 1px;
  height: 25px;
  background-color: #909498;
  top: calc(50% - 12.5px);
  left: 50%;
  transition: ease 0.4s;
  opacity: 1;
}
header .container-mobile-menu .mobile-menu .menu .hidden-block .control-block:hover::before {
  opacity: 0;
}
header .container-mobile-menu .mobile-menu::before {
  content: "";
  background-color: rgba(39, 41, 45, 0.5);
  width: calc(100vw - 260px);
  position: absolute;
  left: 0;
  opacity: 0;
  height: 100%;
}

@media screen and (max-width: 1499px) {
  header .container-fluid {
    width: 100%;
    margin: 0;
  }
  header .top-menu ul li a {
    padding: 0 2.5vw;
  }
}
@media screen and (max-width: 1199px) {
  header {
    height: 80px;
  }
  header .logo {
    width: 80px;
    height: 40px;
  }
  header .top-menu {
    height: 80px;
  }
  header .top-menu ul li a {
    padding: 0 2vw;
    font-size: 12px;
  }
}
@media screen and (max-width: 991px) {
  header {
    height: 60px;
  }
  header .logo {
    width: 80px;
    height: 40px;
  }
  header .top-menu {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  header .logo {
    width: 70px;
    height: 35px;
  }
}
@media screen and (max-width: 575px) {
  header {
    height: 50px;
  }
  header .logo {
    width: 50px;
    height: 25px;
  }
  header .container-mobile-menu {
    z-index: 900;
    width: 100vw;
    height: calc(100dvh - 49px);
  }
  header .container-mobile-menu .mobile-menu .menu .hidden-block .scroll-block {
    height: calc(100% - 51px);
  }
  header .container-mobile-menu .mobile-menu .menu .hidden-block .control-block {
    height: 50px;
  }
  header .btn-mobile-menu {
    height: 50px;
  }
}
footer {
  background-color: #151623;
  margin-top: auto;
}
footer .top-footer {
  padding: 100px 0px 50px;
  border-top: 1px solid #D5D3D4;
  border-bottom: 1px solid #D5D3D4;
}
footer .top-footer .footer-top-text {
  padding-left: 50px;
  margin-bottom: 50px;
  line-height: 46px;
  font-size: 30px;
  color: #fff;
}
footer .top-footer .footer-top-text .big {
  text-transform: uppercase;
}
footer .top-footer .list {
  margin-left: -2vw;
}
footer .top-footer .col-auto {
  width: 14.28vw;
}
footer .top-footer .col-auto .logo-dev {
  width: inherit;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
footer .top-footer .col-auto .logo-dev img {
  display: block;
  height: 60px;
  width: auto;
  object-fit: contain;
  object-position: center;
}
footer .top-footer .col-auto .logo-dev.size-60 img {
  height: 60px;
}
footer .top-footer .col-auto .logo-dev.size-40 img {
  height: 40px;
}
footer .top-footer .col-auto .logo-dev.size-30 img {
  height: 30px;
}
footer .bottom-footer {
  padding: 40px 50px;
}
footer .bottom-footer .logo-copyright {
  display: flex;
}
footer .bottom-footer .logo-copyright .logo {
  height: 57px;
  margin-right: 20px;
}
footer .bottom-footer .logo-copyright .logo img {
  display: block;
  height: inherit;
  object-fit: contain;
  object-position: center;
}
footer .bottom-footer .logo-copyright .copyright {
  font-size: 18px;
  line-height: 30px;
  color: #fff;
}
footer .bottom-footer .politics {
  font-size: 18px;
  line-height: 30px;
  color: #fff;
}

@media screen and (max-width: 1649px) {
  footer .top-footer .list {
    margin-left: -1vw;
  }
  footer .top-footer .col-auto {
    width: 14.1vw;
  }
}
@media screen and (max-width: 1499px) {
  footer .top-footer .footer-top-text {
    padding-left: 0;
  }
  footer .top-footer .list {
    margin-left: 0;
  }
  footer .top-footer .col-auto {
    width: 20%;
  }
  footer .top-footer .col-auto .logo-dev {
    width: 100%;
  }
  footer .bottom-footer {
    padding: 40px 0px;
  }
}
@media screen and (max-width: 1199px) {
  footer .top-footer .footer-top-text {
    font-size: 26px;
    line-height: 34px;
  }
  footer .top-footer .col-auto {
    width: 20%;
  }
  footer .top-footer .col-auto .logo-dev {
    width: 100%;
  }
  footer .top-footer .col-auto .logo-dev.size-60 img {
    height: 40px;
  }
  footer .top-footer .col-auto .logo-dev.size-40 img {
    height: 30px;
  }
  footer .top-footer .col-auto .logo-dev.size-30 img {
    height: 25px;
  }
  footer .bottom-footer {
    padding: 30px 0px;
  }
  footer .bottom-footer .logo-copyright .logo {
    height: 40px;
  }
  footer .bottom-footer .logo-copyright .copyright {
    font-size: 14px;
    line-height: 20px;
  }
  footer .bottom-footer .politics {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 991px) {
  footer .top-footer {
    padding: 50px 0px 25px;
  }
  footer .top-footer .footer-top-text {
    text-align: center;
  }
  footer .top-footer .col-auto {
    width: 25%;
  }
}
@media screen and (max-width: 767px) {
  footer .top-footer .footer-top-text {
    font-size: 22px;
    line-height: 26px;
  }
  footer .top-footer .col-auto {
    width: 33.3333%;
  }
  footer .bottom-footer {
    padding: 30px 0px;
  }
  footer .bottom-footer .logo-copyright .logo {
    height: 30px;
  }
  footer .bottom-footer .logo-copyright .copyright {
    font-size: 12px;
    line-height: 16px;
  }
  footer .bottom-footer .politics {
    font-size: 12px;
    line-height: 16px;
  }
}
@media screen and (max-width: 575px) {
  footer .top-footer .footer-top-text {
    font-size: 16px;
    line-height: 22px;
  }
  footer .top-footer .col-auto {
    width: 50%;
  }
  footer .bottom-footer {
    padding: 30px 0px;
  }
  footer .bottom-footer .logo-copyright {
    margin-bottom: 20px;
  }
  footer .bottom-footer .logo-copyright .copyright {
    text-align: center;
  }
  footer .bottom-footer .politics {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    display: block;
  }
}
@media screen and (max-width: 399px) {
  footer .top-footer .footer-top-text {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 30px;
  }
  footer .top-footer .col-auto {
    width: 50%;
  }
  footer .top-footer .col-auto .logo-dev {
    margin-bottom: 20px;
  }
  footer .top-footer .col-auto .logo-dev.size-60 img {
    height: 30px;
  }
  footer .top-footer .col-auto .logo-dev.size-40 img {
    height: 25px;
  }
  footer .top-footer .col-auto .logo-dev.size-30 img {
    height: 20px;
  }
  footer .bottom-footer {
    padding: 30px 0px;
  }
  footer .bottom-footer .logo-copyright {
    margin-bottom: 20px;
  }
  footer .bottom-footer .logo-copyright .logo {
    margin-right: 10px;
  }
  footer .bottom-footer .logo-copyright .copyright {
    font-size: 10px;
    line-height: 14px;
  }
  footer .bottom-footer .politics {
    font-size: 10px;
    line-height: 14px;
  }
}
.main-first-screen {
  background-color: #151623;
  padding: 70px 0 50px;
  overflow: hidden;
}
.main-first-screen .container-h1 {
  margin-top: 70px;
  position: relative;
}
.main-first-screen .container-h1 h1 {
  text-align: center;
  font-size: 85px;
  line-height: 115px;
  font-weight: 700;
  color: #fff;
  position: relative;
  z-index: 5;
}
.main-first-screen .container-h1 .figure-1 {
  position: absolute;
  z-index: 1;
  top: -35px;
  left: 30px;
}
.main-first-screen .container-h1 .figure-1 .figure-block {
  position: relative;
  display: flex;
  align-items: center;
}
.main-first-screen .container-h1 .figure-1 .figure-block .square {
  background-color: #73737b;
  width: 200px;
  height: 70px;
  margin-right: 50px;
}
.main-first-screen .container-h1 .figure-1 .figure-block .figure-text {
  position: relative;
  width: 175px;
}
.main-first-screen .container-h1 .figure-1 .figure-block .figure-text .icon-text {
  position: absolute;
  width: 20px;
  height: 20px;
  left: -30px;
}
.main-first-screen .container-h1 .figure-1 .figure-block .figure-text .icon-text img {
  width: inherit;
  height: inherit;
  object-fit: contain;
  object-position: center;
  display: block;
}
.main-first-screen .container-h1 .figure-1 .figure-block .figure-text .text {
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
}
.main-first-screen .container-h1 .figure-2 {
  position: absolute;
  z-index: 1;
  top: -70px;
  right: 35px;
}
.main-first-screen .container-h1 .figure-2 .figure-block {
  position: relative;
  display: flex;
}
.main-first-screen .container-h1 .figure-2 .figure-block .square {
  background-color: #6666ff;
  width: 70px;
  height: 140px;
  margin-right: 20px;
}
.main-first-screen .container-h1 .figure-2 .figure-block .figure-text {
  width: 150px;
}
.main-first-screen .container-h1 .figure-2 .figure-block .figure-text .icon-text {
  margin-bottom: 5px;
}
.main-first-screen .container-h1 .figure-2 .figure-block .figure-text .icon-text img {
  width: inherit;
  height: inherit;
  object-fit: contain;
  object-position: center;
  display: block;
}
.main-first-screen .container-h1 .figure-2 .figure-block .figure-text .text {
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
}
.main-first-screen .first-text {
  margin-top: 70px;
  position: relative;
  margin-bottom: 70px;
}
.main-first-screen .first-text .figure-3 {
  z-index: 1;
  position: absolute;
  left: 30px;
  top: -10px;
}
.main-first-screen .first-text .figure-3 .figure-block {
  position: relative;
  display: flex;
}
.main-first-screen .first-text .figure-3 .figure-block .square {
  background-color: #b23d6d;
  width: 200px;
  height: 90px;
}
.main-first-screen .first-text .figure-3 .figure-block .figure-text {
  width: 130px;
  position: relative;
  margin-right: 20px;
}
.main-first-screen .first-text .figure-3 .figure-block .figure-text .icon-text {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 10px;
  bottom: 10px;
}
.main-first-screen .first-text .figure-3 .figure-block .figure-text .icon-text img {
  width: inherit;
  height: inherit;
  object-fit: contain;
  object-position: center;
  display: block;
}
.main-first-screen .first-text .figure-3 .figure-block .figure-text .text {
  font-size: 16px;
  text-align: left;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
}
.main-first-screen .first-text .text {
  position: relative;
  z-index: 5;
  font-size: 45px;
  line-height: 60px;
  color: #fff;
  text-align: center;
}
.main-first-screen .container-icons {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  border-top: #666666 2px solid;
  border-bottom: #666666 2px solid;
  padding: 50px 0;
}
.main-first-screen .container-icons .icon {
  width: 40px;
  height: 40px;
  margin: 0 15px;
}
.main-first-screen .container-icons .icon img {
  display: block;
  width: inherit;
  height: inherit;
  object-fit: contain;
  object-position: center;
}

@media screen and (max-width: 1399px) {
  .main-first-screen .container-h1 h1 {
    font-size: 70px;
    line-height: 90px;
  }
  .main-first-screen .first-text .text {
    font-size: 40px;
    line-height: 50px;
  }
  .main-first-screen .first-text .figure-3 {
    top: -15px;
  }
  .main-first-screen .first-text .figure-3 .figure-block .figure-text {
    margin-right: 0px;
  }
}
@media screen and (max-width: 1199px) {
  .main-first-screen .container-h1 h1 {
    font-size: 60px;
    line-height: 70px;
  }
  .main-first-screen .container-h1 .figure-1 .figure-block .square {
    width: 120px;
    height: 60px;
    margin-right: 30px;
  }
  .main-first-screen .container-h1 .figure-1 .figure-block .figure-text .icon-text {
    width: 14px;
    height: 14px;
    left: -20px;
  }
  .main-first-screen .container-h1 .figure-1 .figure-block .figure-text .text {
    font-size: 14px;
    line-height: 16px;
  }
  .main-first-screen .container-h1 .figure-2 {
    right: 0;
  }
  .main-first-screen .container-h1 .figure-2 .figure-block .square {
    width: 60px;
    height: 120px;
  }
  .main-first-screen .container-h1 .figure-2 .figure-block .figure-text .icon-text {
    width: 14px;
    height: 14px;
    left: 0px;
  }
  .main-first-screen .container-h1 .figure-2 .figure-block .figure-text .text {
    font-size: 14px;
    line-height: 16px;
  }
  .main-first-screen .first-text {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .main-first-screen .first-text .text {
    font-size: 30px;
    line-height: 40px;
  }
  .main-first-screen .first-text .figure-3 {
    top: -10px;
  }
  .main-first-screen .first-text .figure-3 .figure-block .square {
    height: 64px;
    width: 140px;
  }
  .main-first-screen .first-text .figure-3 .figure-block .figure-text {
    margin-right: 0px;
  }
  .main-first-screen .first-text .figure-3 .figure-block .figure-text .icon-text {
    width: 14px;
    height: 14px;
    right: 20px;
    bottom: 0px;
  }
  .main-first-screen .first-text .figure-3 .figure-block .figure-text .text {
    font-size: 14px;
    line-height: 16px;
  }
}
@media screen and (max-width: 991px) {
  .main-first-screen .container-h1 h1 {
    font-size: 46px;
    line-height: 56px;
  }
  .main-first-screen .container-h1 .figure-1 {
    top: -45px;
  }
  .main-first-screen .first-text .text {
    font-size: 20px;
    line-height: 24px;
  }
  .main-first-screen .first-text .figure-3 {
    top: -20px;
    left: 0;
  }
  .main-first-screen .container-icons {
    padding: 30px 0 15px;
    flex-wrap: wrap;
  }
  .main-first-screen .container-icons .icon {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 767px) {
  .main-first-screen {
    padding: 0px 0 20px;
  }
  .main-first-screen .container-h1 h1 {
    font-size: 38px;
    line-height: 48px;
  }
  .main-first-screen .container-h1 .figure-1 {
    top: -40px;
    left: -12px;
  }
  .main-first-screen .container-h1 .figure-1 .figure-block .square {
    width: 90px;
    height: 50px;
  }
  .main-first-screen .container-h1 .figure-1 .figure-block .figure-text .text {
    font-size: 12px;
    line-height: 14px;
  }
  .main-first-screen .container-h1 .figure-2 {
    right: -50px;
    top: -50px;
  }
  .main-first-screen .container-h1 .figure-2 .figure-block .square {
    width: 50px;
    height: 90px;
  }
  .main-first-screen .container-h1 .figure-2 .figure-block .figure-text .text {
    font-size: 12px;
    line-height: 14px;
  }
  .main-first-screen .first-text .text {
    font-size: 16px;
    line-height: 20px;
  }
  .main-first-screen .first-text .figure-3 {
    top: -18px;
    left: -10px;
  }
  .main-first-screen .first-text .figure-3 .figure-block .square {
    height: 56px;
    width: 92px;
  }
  .main-first-screen .first-text .figure-3 .figure-block .figure-text {
    width: 100px;
  }
  .main-first-screen .first-text .figure-3 .figure-block .figure-text .icon-text {
    right: 12px;
  }
  .main-first-screen .first-text .figure-3 .figure-block .figure-text .text {
    font-size: 12px;
    line-height: 14px;
  }
}
@media screen and (max-width: 575px) {
  .main-first-screen .container-h1 h1 {
    font-size: 29px;
    line-height: 39px;
  }
  .main-first-screen .container-h1 .figure-1 {
    top: -40px;
    left: -12px;
  }
  .main-first-screen .container-h1 .figure-1 .figure-block .square {
    width: 60px;
    height: 40px;
    margin-right: 15px;
  }
  .main-first-screen .container-h1 .figure-1 .figure-block .figure-text .icon-text {
    width: 10px;
    height: 10px;
    left: -12px;
  }
  .main-first-screen .container-h1 .figure-1 .figure-block .figure-text .text {
    font-size: 9px;
    line-height: 12px;
  }
  .main-first-screen .container-h1 .figure-2 {
    right: -70px;
    top: -50px;
  }
  .main-first-screen .container-h1 .figure-2 .figure-block .square {
    width: 40px;
    height: 70px;
    margin-right: 15px;
  }
  .main-first-screen .container-h1 .figure-2 .figure-block .figure-text .icon-text {
    width: 10px;
    height: 10px;
  }
  .main-first-screen .container-h1 .figure-2 .figure-block .figure-text .text {
    font-size: 9px;
    line-height: 12px;
  }
  .main-first-screen .first-text .figure-3 {
    top: -30px;
    left: 0;
  }
  .main-first-screen .first-text .figure-3 .figure-block .square {
    height: 50px;
    width: 80px;
  }
  .main-first-screen .first-text .figure-3 .figure-block .figure-text {
    width: 130px;
  }
  .main-first-screen .first-text .figure-3 .figure-block .figure-text .icon-text {
    width: 10px;
    height: 10px;
    bottom: 25px;
  }
  .main-first-screen .first-text .figure-3 .figure-block .figure-text .text {
    font-size: 9px;
    line-height: 12px;
  }
}
.main-second-screen {
  background-color: #151623;
  padding: 70px 0 50px;
  overflow: hidden;
}
.main-second-screen .block {
  margin-bottom: 60px;
}
.main-second-screen h2 {
  font-size: 85px;
  line-height: 115px;
  text-align: center;
  color: #fff;
  margin-bottom: 120px;
}
.main-second-screen h3 {
  font-size: 48px;
  line-height: 65px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 30px;
}
.main-second-screen p {
  color: #73737b;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 0;
}
.main-second-screen .container-h {
  position: relative;
}
.main-second-screen .container-h .figure-1 {
  position: absolute;
  bottom: -100px;
  left: 30px;
  width: 210px;
}
.main-second-screen .container-h .figure-1 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.main-second-screen .container-h .figure-2 {
  position: absolute;
  right: 50px;
  bottom: -175px;
  width: 234px;
}
.main-second-screen .container-h .figure-2 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.main-second-screen .block-img {
  width: 22.34vw;
  height: auto;
}
.main-second-screen .block-img img {
  display: block;
  width: inherit;
  height: inherit;
  object-fit: contain;
  object-position: top;
}
.main-second-screen .block-img.img-1 {
  width: 25.77vw;
}
.main-second-screen .block-img.img-2 {
  width: 27.77vw;
}
.main-second-screen .right {
  padding-right: 3.41vw;
}
.main-second-screen .left {
  padding-left: 3.41vw;
}

@media screen and (max-width: 1649px) {
  .main-second-screen h3 {
    font-size: 40px;
    line-height: 50px;
  }
  .main-second-screen p {
    font-size: 28px;
    line-height: 38px;
  }
  .main-second-screen .container-h .figure-2 {
    bottom: -140px;
    width: 190px;
  }
}
@media screen and (max-width: 1499px) {
  .main-second-screen .container-h .figure-2 {
    width: 160px;
  }
}
@media screen and (max-width: 1399px) {
  .main-second-screen h2 {
    font-size: 70px;
    line-height: 90px;
    padding: 0 30px;
  }
  .main-second-screen .container-h .figure-1 {
    width: 190px;
  }
  .main-second-screen .container-h .figure-2 {
    width: 140px;
    bottom: -90px;
  }
}
@media screen and (max-width: 1199px) {
  .main-second-screen h2 {
    font-size: 60px;
    line-height: 70px;
  }
  .main-second-screen h3 {
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 20px;
  }
  .main-second-screen p {
    font-size: 24px;
    line-height: 30px;
  }
  .main-second-screen .container-h .figure-1 {
    width: 190px;
  }
  .main-second-screen .container-h .figure-2 {
    width: 110px;
    bottom: -70px;
  }
}
@media screen and (max-width: 991px) {
  .main-second-screen h2 {
    font-size: 46px;
    line-height: 56px;
    padding: 0 0px;
  }
  .main-second-screen .container-h .figure-1 {
    width: 160px;
  }
  .main-second-screen .container-h .figure-2 {
    width: 110px;
    right: 250px;
    bottom: -100px;
  }
  .main-second-screen .block-img {
    position: relative;
  }
  .main-second-screen .block-img.img-1 {
    margin-top: -120px;
    margin-left: auto;
  }
  .main-second-screen .block-img.img-2 {
    margin-top: -270px;
    margin-right: auto;
  }
  .main-second-screen p.right {
    padding-right: calc(25.77vw + 20px);
  }
  .main-second-screen p.left {
    padding-left: calc(27.77vw + 20px);
  }
  .main-second-screen h3.right {
    padding-right: 0;
  }
  .main-second-screen h3.left {
    padding-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .main-second-screen h2 {
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 100px;
  }
  .main-second-screen .container-h .figure-1 {
    width: 120px;
    left: 10px;
    bottom: -80px;
  }
  .main-second-screen h3 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .main-second-screen p {
    font-size: 20px;
    line-height: 24px;
  }
  .main-second-screen .block-img.img-2 {
    margin-top: -260px;
  }
  .main-second-screen p.left:last-child {
    margin-top: 5px;
    padding-left: 0;
  }
}
@media screen and (max-width: 575px) {
  .main-second-screen {
    padding: 30px 0 25px;
  }
  .main-second-screen .block {
    margin-bottom: 30px;
  }
  .main-second-screen h2 {
    margin-bottom: 40px;
    font-size: 29px;
    line-height: 39px;
  }
  .main-second-screen h3 {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .main-second-screen p.right {
    padding-right: 0;
  }
  .main-second-screen p.left {
    padding-left: 0;
  }
  .main-second-screen p {
    font-size: 16px;
    line-height: 20px;
  }
  .main-second-screen .block-img {
    margin-top: 20px;
  }
  .main-second-screen .block-img.img-2 {
    width: 100%;
    margin-top: 20px;
  }
  .main-second-screen .block-img.img-1 {
    width: 100%;
    margin-top: 20px;
  }
  .main-second-screen .container-h .figure-1 {
    display: none;
  }
  .main-second-screen .container-h .figure-2 {
    display: none;
  }
}
.main-third-screen {
  background-color: #151623;
  padding-bottom: 100px;
  overflow: hidden;
}
.main-third-screen h2 {
  font-size: 85px;
  line-height: 115px;
  text-align: center;
  color: #fff;
  margin-bottom: 60px;
}
.main-third-screen h2 span {
  color: #6666FF;
}
.main-third-screen .block-text {
  position: relative;
  margin-bottom: 60px;
}
.main-third-screen .block-text .figure-1 {
  position: absolute;
  z-index: 1;
  right: -100px;
  bottom: -140px;
  width: 85px;
}
.main-third-screen .block-text .figure-1 img {
  width: 100%;
  display: block;
}
.main-third-screen .block-text p {
  position: relative;
  z-index: 5;
  font-size: 30px;
  line-height: 40px;
  color: #73737b;
}
.main-third-screen .list {
  margin-bottom: 40px;
}
.main-third-screen .list li {
  font-size: 48px;
  text-align: center;
  line-height: 60px;
  color: #fff;
}
.main-third-screen .figure-2 {
  width: 45.44vw;
  margin-bottom: 90px;
}
.main-third-screen .figure-2 img {
  display: block;
  width: inherit;
  object-fit: contain;
}
.main-third-screen .btn {
  margin-bottom: 100px;
}
.main-third-screen .figure-3 {
  width: 24.47vw;
  margin-left: 50px;
}
.main-third-screen .figure-3 img {
  display: block;
  width: inherit;
  object-fit: contain;
}
.main-third-screen .big {
  font-size: 48px;
  line-height: 65px;
  color: #fff;
  width: 580px;
  margin-left: 50px;
}

@media screen and (max-width: 1649px) {
  .main-third-screen .block-text p {
    font-size: 28px;
    line-height: 38px;
  }
}
@media screen and (max-width: 1399px) {
  .main-third-screen h2 {
    font-size: 70px;
    line-height: 90px;
  }
  .main-third-screen .list li {
    font-size: 36px;
    line-height: 46px;
  }
  .main-third-screen .big {
    font-size: 36px;
    line-height: 46px;
  }
}
@media screen and (max-width: 1199px) {
  .main-third-screen h2 {
    font-size: 60px;
    line-height: 70px;
  }
  .main-third-screen .block-text p {
    font-size: 24px;
    line-height: 30px;
  }
  .main-third-screen .list li {
    font-size: 32px;
    line-height: 36px;
  }
  .main-third-screen .big {
    font-size: 32px;
    line-height: 36px;
    margin-left: 0;
  }
  .main-third-screen .figure-3 {
    margin-left: 0;
  }
}
@media screen and (max-width: 991px) {
  .main-third-screen h2 {
    font-size: 46px;
    line-height: 56px;
  }
  .main-third-screen .block-text .figure-1 {
    bottom: -120px;
    width: 70px;
  }
  .main-third-screen .figure-2 {
    width: 80.44vw;
    margin-bottom: 40px;
  }
  .main-third-screen .big {
    font-size: 32px;
    line-height: 36px;
    margin-left: 0;
    width: 100%;
  }
  .main-third-screen .figure-3 {
    margin-left: 0;
    width: 100%;
  }
  .main-third-screen .figure-3 img {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .main-third-screen h2 {
    font-size: 38px;
    line-height: 48px;
  }
  .main-third-screen .block-text {
    margin-bottom: 30px;
  }
  .main-third-screen .block-text p {
    font-size: 20px;
    line-height: 24px;
  }
  .main-third-screen .block-text .figure-1 {
    right: -73px;
    bottom: -120px;
    width: 60px;
  }
  .main-third-screen .list li {
    font-size: 26px;
    line-height: 30px;
  }
  .main-third-screen .big {
    font-size: 25px;
    line-height: 29px;
  }
}
@media screen and (max-width: 575px) {
  .main-third-screen h2 {
    font-size: 29px;
    line-height: 39px;
    margin-bottom: 40px;
  }
  .main-third-screen .block-text {
    margin-bottom: 20px;
  }
  .main-third-screen .block-text p {
    font-size: 16px;
    line-height: 20px;
  }
  .main-third-screen .block-text p br {
    display: none;
  }
  .main-third-screen .block-text .figure-1 {
    display: none;
  }
  .main-third-screen .list li {
    font-size: 20px;
    line-height: 24px;
  }
  .main-third-screen .btn {
    margin-bottom: 30px;
  }
  .main-third-screen .big {
    margin-top: 20px;
    font-size: 20px;
    line-height: 24px;
  }
}
.publications-page .publications-list .publication {
  display: flex;
  margin-bottom: 40px;
}
.publications-page .publications-list .publication .publication-img {
  margin-right: 40px;
  width: 197px;
  height: 278px;
  flex-shrink: 0;
}
.publications-page .publications-list .publication .publication-img img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: top;
}
.publications-page .publications-list .publication .publication-info .block-sm {
  display: contents;
}
.publications-page .publications-list .publication .publication-info .name {
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 30px;
  font-weight: 700;
}
.publications-page .publications-list .publication .publication-info .value {
  font-size: 20px;
  line-height: 30px;
}
.publications-page .publications-list .publication .publication-info .text {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}
.publications-page .publications-list .publication .publication-info .link {
  color: #3072C4;
  font-size: 20px;
  line-height: 30px;
}

@media screen and (max-width: 1199px) {
  .publications-page .publications-list .publication .publication-info .name {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 15px;
  }
  .publications-page .publications-list .publication .publication-info .value {
    font-size: 18px;
    line-height: 26px;
  }
  .publications-page .publications-list .publication .publication-info .text {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
  }
}
@media screen and (max-width: 991px) {
  .publications-page .publications-list .publication {
    margin-bottom: 20px;
  }
  .publications-page .publications-list .publication .publication-img {
    margin-right: 15px;
  }
  .publications-page .publications-list .publication .publication-info .name {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
  }
  .publications-page .publications-list .publication .publication-info .value {
    font-size: 16px;
    line-height: 20px;
  }
  .publications-page .publications-list .publication .publication-info .text {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
  }
}
@media screen and (max-width: 767px) {
  .publications-page .publications-list .publication {
    margin-bottom: 30px;
    flex-wrap: wrap;
  }
  .publications-page .publications-list .publication .publication-img {
    margin-bottom: 30px;
  }
  .publications-page .publications-list .publication .publication-info {
    display: contents;
  }
  .publications-page .publications-list .publication .publication-info .block-sm {
    display: block;
    width: calc(100% - 220px);
  }
  .publications-page .publications-list .publication .publication-info .name {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
  }
  .publications-page .publications-list .publication .publication-info .value {
    font-size: 16px;
    line-height: 20px;
  }
  .publications-page .publications-list .publication .publication-info .text {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
  }
  .publications-page .publications-list .publication .publication-info .date {
    width: 100%;
  }
  .publications-page .publications-list .publication .publication-info .link {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .publications-page .publications-list .publication {
    margin-bottom: 30px;
    flex-wrap: wrap;
  }
  .publications-page .publications-list .publication .publication-img {
    width: 34.26vw;
    height: auto;
    margin-bottom: 20px;
  }
  .publications-page .publications-list .publication .publication-info .block-sm {
    width: calc(100% - 24px - 34.26vw);
  }
  .publications-page .publications-list .publication .publication-info .name {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .publications-page .publications-list .publication .publication-info .value {
    font-size: 14px;
    line-height: 16px;
  }
  .publications-page .publications-list .publication .publication-info .text {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
  }
}
@media screen and (max-width: 399px) {
  .publications-page .publications-list .publication .publication-img {
    width: 25vw;
    height: auto;
    margin-bottom: 10px;
  }
  .publications-page .publications-list .publication .publication-info .block-sm {
    width: calc(100% - 24px - 25vw);
  }
  .publications-page .publications-list .publication .publication-info .name {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
  }
  .publications-page .publications-list .publication .publication-info .autors {
    font-size: 12px;
    line-height: 14px;
  }
  .publications-page .publications-list .publication .publication-info .link {
    font-size: 14px;
    line-height: 16px;
  }
}
.table-section .table-block {
  background-color: #fff;
  border: 1px solid #E2E8F0;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
}
.table-section .table-block table {
  width: 100%;
}
.table-section .table-block table thead tr th {
  padding: 0;
}
.table-section .table-block table thead tr th .content-th {
  padding: 5px 10px;
}
.table-section .table-block table thead tr th .content-th .name-column {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #383836;
}
.table-section .table-block table thead tr th .content-th .search-column {
  margin-bottom: 5px;
}
.table-section .table-block table thead tr th .content-th input {
  border: 2px solid #d2d2d2;
  border-radius: 16px;
  padding: 10px 10px;
  font-size: 12px;
  line-height: 1;
  display: block;
  width: 100%;
}
.table-section .table-block table thead tr th .content-th input:focus {
  border: 2px solid #6f42c1;
}
.table-section .table-block table thead tr th .content-th .select {
  width: 100%;
  height: 38px;
  border: 2px solid #d2d2d2;
  border-radius: 16px;
  margin-bottom: 5px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px;
}
.table-section .table-block table thead tr th .content-th .select .select-value {
  flex-grow: 1;
  max-width: 100%;
}
.table-section .table-block table thead tr th .content-th .select .select-icon {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
}
.table-section .table-block table thead tr th .content-th .select .select-icon:hover {
  background-color: #efefff;
}
.table-section .table-block table thead tr th .content-th .select .select-icon::before {
  content: "";
  position: absolute;
  display: block;
  width: 8px;
  height: 8px;
  border: 2px solid #000;
  border-top: none;
  border-left: none;
  transform: rotate(45deg);
  left: calc(50% - 4px);
  top: calc(50% - 6px);
}
.table-section .table-block table thead tr th .content-th .select .select-list {
  display: none;
}
.table-section .table-block table thead tr th .content-th .select.select-open {
  border-radius: 16px 16px 0 0;
  border-color: #6f42c1;
}
.table-section .table-block table thead tr th .content-th .select.select-open .select-icon::before {
  transform: rotate(225deg);
  top: calc(50% - 2px);
}
.table-section .table-block table thead tr th .content-th .select.select-open .select-list {
  position: absolute;
  display: block;
  background-color: #fff;
  border: 2px solid #6f42c1;
  top: 100%;
  width: calc(100% + 4px);
  z-index: 5;
  left: -2px;
  border-radius: 0 0 16px 16px;
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.table-section .table-block table thead tr th .content-th .select.select-open .select-list ul li {
  padding: 8px 10px;
  font-weight: 400;
  background-color: #fff;
  cursor: pointer;
  font-size: 12px;
}
.table-section .table-block table thead tr th .content-th .select.select-open .select-list ul li:hover {
  background-color: #efefff;
}
.table-section .table-block table thead tr th:nth-child(1) {
  width: 1%;
  min-width: 25px;
}
.table-section .table-block table thead tr th:nth-child(3) {
  width: 13.1%;
}
.table-section .table-block table thead tr th:nth-child(4) {
  width: 10%;
  min-width: 140px;
}
.table-section .table-block table thead tr th:nth-child(5) {
  width: 10%;
  min-width: 140px;
}
.table-section .table-block table thead tr th:nth-child(6) {
  width: 3.275%;
}
.table-section .table-block table thead tr th:nth-child(7) {
  width: 3.275%;
}
.table-section .table-block table tbody tr {
  padding: 10px;
}
.table-section .table-block table tbody tr.group-properties td .content-td {
  padding: 10px 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  background-color: rgba(102, 102, 255, 0.1);
}
.table-section .table-block table tbody tr.property td .content-td {
  padding: 5px 10px;
  font-size: 14px;
  color: #5e5c5c;
  font-weight: 500;
}
.table-section .table-block table tbody tr.property .property-checkbox {
  display: flex;
  align-items: center;
}
.table-section .table-block table tbody tr.property .property-checkbox label {
  overflow: hidden;
  border: 2px solid #ababa6;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  left: -5px;
}
.table-section .table-block table tbody tr.property .property-checkbox label input {
  z-index: 1;
  left: -999px;
  opacity: 0;
  border: none;
  position: absolute;
}
.table-section .table-block table tbody tr.property .property-checkbox label::before {
  content: "";
  position: absolute;
  border: 2px solid #fff;
  width: 6px;
  height: 9px;
  border-top: none;
  border-left: none;
  transform: rotate(45deg);
  left: 4px;
  top: 1px;
}
.table-section .table-block table tbody tr.property .property-checkbox label.checked {
  background-color: #6f42c1;
  border-color: #6f42c1;
}
.table-section .table-block table tbody tr.property .property-icon-num-name {
  display: flex;
  align-items: center;
}
.table-section .table-block table tbody tr.property .property-icon-num-name .property-icon {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}
.table-section .table-block table tbody tr.property .property-icon-num-name .property-icon img {
  width: 100%;
  object-fit: contain;
}
.table-section .table-block table tbody tr.property .property-icon-num-name .separator {
  margin: 0 5px;
}
.table-section .table-block table tbody tr.property .property-status {
  height: 38px;
  width: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-weight: 400;
}
.table-section .table-block table tbody tr.property .property-status.property-status-new {
  background-color: #e5e5e5;
}
.table-section .table-block table tbody tr.property .property-status.property-status-work {
  background-color: #CCCCFC;
}
.table-section .table-block table tbody tr.property .property-status.property-status-agreed {
  background-color: #D7F2DE;
}
.table-section .table-block table tbody tr.property .property-status.property-status-not {
  background-color: #F5D4E2;
}
.table-section .table-block table tbody tr:hover {
  background-color: #fcfcfc;
}
.table-section .list-props {
  width: 350px;
  padding-left: 20px;
}
.table-section .list-props ul li {
  margin-bottom: 10px;
}
.table-section .list-props ul li .text {
  color: #848484;
  font-size: 16px;
  line-height: 19px;
}
.table-section .list-props ul li.parent {
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
}
.table-section .list-props ul li.parent > .text {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}
.table-section .list-props ul li.parent .child-list {
  display: none;
  padding: 10px 0 10px 20px;
}
.table-section .list-props ul li.parent .child-list ul li {
  margin-bottom: 0px;
}
.table-section .list-props ul li.parent.parent-open .child-list {
  display: block;
}
.table-section .list-props ul li:hover > .text {
  color: #6f42c1;
}
.table-section .list-props .list-control {
  margin-top: 40px;
}
.table-section .list-props .list-control .btn {
  height: 45px;
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  width: calc(100% - 15px);
}

.parametr-section .list-props {
  width: 350px;
  padding-left: 20px;
}
.parametr-section .list-props ul li {
  margin-bottom: 10px;
}
.parametr-section .list-props ul li .text {
  color: #848484;
  font-size: 16px;
  line-height: 19px;
}
.parametr-section .list-props ul li.parent {
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
}
.parametr-section .list-props ul li.parent > .text {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}
.parametr-section .list-props ul li.parent .child-list {
  display: none;
  padding: 10px 0 10px 20px;
}
.parametr-section .list-props ul li.parent .child-list ul li {
  margin-bottom: 0px;
}
.parametr-section .list-props ul li.parent.parent-open .child-list {
  display: block;
}
.parametr-section .list-props ul li:hover > .text {
  color: #6f42c1;
}
.parametr-section .list-props .list-control {
  margin-top: 40px;
}
.parametr-section .list-props .list-control .btn {
  height: 45px;
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  width: calc(100% - 15px);
}
.parametr-section .parametr-block {
  border: 1px solid #E2E8F0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
}
.parametr-section .parametr-block .name-block {
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #E5E5E5;
  color: #211F35;
  font-size: 20px;
  line-height: 1;
  font-weight: 700;
}
.parametr-section .parametr-block .fields-block {
  margin-bottom: 30px;
}
.parametr-section .parametr-block .fields-block .container-fields {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.parametr-section .parametr-block .fields-block .container-fields .field-label {
  font-size: 14px;
  line-height: 1;
  text-align: right;
  margin-right: 15px;
  width: 20%;
  max-width: 175px;
}
.parametr-section .parametr-block .fields-block .container-fields .field-value {
  margin-right: 15px;
  background-color: #FBF9FD;
  border: 1px solid #DDDEE3;
  border-radius: 10px;
  font-size: 14px;
  line-height: 1;
  padding: 10px;
  color: #262626;
  width: 66.6666%;
}
.parametr-section .parametr-block .fields-block .container-fields .field-value.field-size-small {
  width: 26.8%;
}
.parametr-section .parametr-block .fields-block .container-fields .field-status {
  margin-left: auto;
  width: 13%;
  text-align: center;
  border-radius: 100px;
  font-size: 12px;
  color: #000000;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.parametr-section .parametr-block .fields-block .container-fields .field-status.field-status-new {
  background-color: #e5e5e5;
}
.parametr-section .parametr-block .fields-block .container-fields .field-status.field-status-not {
  background-color: #F5D4E2;
}
.parametr-section .parametr-block .fields-block .container-fields .field-status.field-status-agreed {
  background-color: #C4FAA4;
}
.parametr-section .parametr-block .fields-block .container-fields .field-status.field-status-work {
  background-color: #CCCCFC;
}
.parametr-section .parametr-block .fields-block .container-fields .field-correl {
  margin-right: 15px;
  width: 25px;
}
.parametr-section .parametr-block .fields-block .container-fields .field-correl img {
  display: block;
  width: 100%;
  object-fit: contain;
  object-position: center;
}
.parametr-section .parametr-block .categories-block {
  display: flex;
}
.parametr-section .parametr-block .categories-block .container-categories {
  width: 80%;
}
.parametr-section .parametr-block .categories-block .container-categories .categories-name {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #727CF5;
  align-items: center;
}
.parametr-section .parametr-block .categories-block .container-categories .categories-name .name {
  color: #09A1DF;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 700;
  margin-right: 15px;
}
.parametr-section .parametr-block .categories-block .container-categories .categories-name .icon {
  width: 24px;
  margin-bottom: 2px;
}
.parametr-section .parametr-block .categories-block .container-categories .categories-name .icon img {
  display: block;
  width: 100%;
  object-fit: contain;
  object-position: center;
}
.parametr-section .parametr-block .categories-block .container-categories .categories-selected {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  margin: 10px -5px 30px;
}
.parametr-section .parametr-block .categories-block .container-categories .categories-selected .category-selected {
  background-color: #EEF2F7;
  border-radius: 100px;
  margin: 10px 5px;
  padding: 12px 40px 12px 15px;
  font-weight: 600;
  font-size: 14px;
  color: #6D767E;
  line-height: 1;
  position: relative;
}
.parametr-section .parametr-block .categories-block .container-categories .categories-selected .category-selected::before {
  content: "";
  display: block;
  width: 2px;
  height: 12px;
  position: absolute;
  right: 20px;
  background-color: #C4C4C4;
  top: calc(50% - 6px);
  transform: rotate(45deg);
}
.parametr-section .parametr-block .categories-block .container-categories .categories-selected .category-selected::after {
  content: "";
  display: block;
  width: 2px;
  height: 12px;
  position: absolute;
  right: 20px;
  background-color: #C4C4C4;
  top: calc(50% - 6px);
  transform: rotate(-45deg);
}
.parametr-section .parametr-block .categories-block .categories-status {
  margin-left: auto;
  width: 13%;
  text-align: center;
  border-radius: 100px;
  font-size: 12px;
  color: #000000;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.parametr-section .parametr-block .categories-block .categories-status.categories-status-new {
  background-color: #e5e5e5;
}
.parametr-section .parametr-block .categories-block .categories-status.categories-status-not {
  background-color: #F5D4E2;
}
.parametr-section .parametr-block .categories-block .categories-status.categories-status-agreed {
  background-color: #C4FAA4;
}
.parametr-section .parametr-block .categories-block .categories-status.categories-status-work {
  background-color: #CCCCFC;
}
.parametr-section .parametr-block .table-block .table-name {
  color: #09A1DF;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 700;
  border-bottom: 1px solid #727CF5;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.parametr-section .parametr-block .table-block table {
  width: 100%;
}
.parametr-section .parametr-block .table-block table thead tr {
  border-bottom: 1px solid #C4C4C4;
}
.parametr-section .parametr-block .table-block table tr th {
  padding: 5px 5px 15px;
}
.parametr-section .parametr-block .table-block table tr th:nth-child(3) {
  width: 14%;
  text-align: center;
}
.parametr-section .parametr-block .table-block table tr th:nth-child(2) {
  text-align: center;
}
.parametr-section .parametr-block .table-block table tr td {
  padding: 10px;
}
.parametr-section .parametr-block .table-block table tr td:nth-child(2) {
  text-align: center;
}
.parametr-section .parametr-block .table-block table tr td .content-td {
  color: #000000;
  font-size: 14px;
  line-height: 16px;
}
.parametr-section .parametr-block .table-block table tr td .content-td .status {
  text-align: center;
  border-radius: 100px;
  font-size: 12px;
  color: #000000;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.parametr-section .parametr-block .table-block table tr td .content-td .status.status-new {
  background-color: #e5e5e5;
}
.parametr-section .parametr-block .table-block table tr td .content-td .status.status-not {
  background-color: #F5D4E2;
}
.parametr-section .parametr-block .table-block table tr td .content-td .status.status-agreed {
  background-color: #C4FAA4;
}
.parametr-section .parametr-block .table-block table tr td .content-td .status.status-work {
  background-color: #CCCCFC;
}

.parametrs-page .text-block {
  color: #383836;
  font-size: 24px;
  line-height: 36px;
}
.parametrs-page h2 {
  color: #383836;
  font-size: 45px;
  line-height: 52px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.parametrs-page .table-block {
  width: 100%;
  overflow: auto;
  border: 1px solid #E2E8F0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #fff;
}
.parametrs-page .table-block table tr th .content-th {
  padding: 0 10px 10px 10px;
}
.parametrs-page .table-block table tr th .content-th .text {
  font-weight: 500;
  font-size: 16px;
  color: #383836;
}
.parametrs-page .table-block table tr th:nth-child(1) {
  width: 25%;
  min-width: 200px;
}
.parametrs-page .table-block table tr th:nth-child(2) {
  width: 25%;
  min-width: 200px;
}
.parametrs-page .table-block table tr th:nth-child(3) {
  width: 40%;
  min-width: 200px;
}
.parametrs-page .table-block table tr th:nth-child(4) {
  width: 100px;
  min-width: 100%;
}
.parametrs-page .table-block table tbody tr:nth-child(odd) {
  background-color: #FAFAFA;
}
.parametrs-page .table-block table tbody tr td {
  padding: 0;
}
.parametrs-page .table-block table tbody tr td .content-td {
  padding: 10px;
}
.parametrs-page .table-block table tbody tr td .content-td .text {
  font-size: 16px;
  line-height: 25px;
  color: #383836;
}
.parametrs-page .table-block table tbody tr td .content-td .container-dev {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -10px;
}
.parametrs-page .table-block table tbody tr td .content-td .container-dev .dev-logo {
  margin: 10px;
}
.parametrs-page .table-block table tbody tr td .content-td .container-dev .dev-logo img {
  display: block;
  height: 30px;
  object-fit: contain;
  object-position: center;
}
.parametrs-page .table-block table tbody tr td .content-td .btn {
  width: 97px;
  height: 27px;
  font-size: 14px;
  line-height: 16px;
  border-radius: 5px;
}

@media screen and (max-width: 1199px) {
  .parametrs-page .text-block {
    font-size: 20px;
    line-height: 24px;
  }
  .parametrs-page h2 {
    font-size: 30px;
    line-height: 40px;
  }
}
@media screen and (max-width: 767px) {
  .parametrs-page .table-block table tr th .content-th {
    padding: 10px 10px 10px 10px;
  }
  .parametrs-page .table-block table tr th .content-th .text {
    font-weight: 500;
    font-size: 14px;
    color: #383836;
  }
  .parametrs-page .table-block table tbody tr td .content-td .text {
    font-size: 14px;
  }
  .parametrs-page .table-block table tbody tr td .content-td .container-dev .dev-logo img {
    height: 20px;
  }
}
@media screen and (max-width: 767px) {
  .parametrs-page .text-block {
    font-size: 16px;
    line-height: 20px;
  }
  .parametrs-page h2 {
    font-size: 26px;
    line-height: 30px;
  }
}