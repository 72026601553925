.main-second-screen{
  background-color: #151623;
  padding: 70px 0 50px;
  overflow: hidden;
  .block{
    margin-bottom: 60px;
  }
  h2{
    font-size: 85px;
    line-height: 115px;
    text-align: center;
    color: #fff;
    margin-bottom: 120px;
  }
  h3{
    font-size: 48px;
    line-height: 65px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 30px;
  }
  p{
    color: #73737b;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 0;
  }
  .container-h{
    position: relative;
    .figure-1{
      position: absolute;
      bottom: -100px;
      left: 30px;
      width: 210px;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }


    }
    .figure-2{
      position: absolute;
      right: 50px;
      bottom: -175px;
      width: 234px;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }

    }
  }
  .block-img{
    width: 22.34vw;
    height: auto;
    img{
      display: block;
      width: inherit;
      height: inherit;
      object-fit: contain;
      object-position: top;

    }
    &.img-1{
      width: 25.77vw;
    }
    &.img-2{
      width: 27.77vw;
    }
  }
  .right{
    padding-right: 3.41vw;
  }
  .left{
    padding-left: 3.41vw;
  }

}

@media screen and (max-width: 1649px){
  .main-second-screen{
    h3{
      font-size: 40px;
      line-height: 50px;
    }
    p{
      font-size: 28px;
      line-height: 38px;
    }
    .container-h{
      .figure-2{
        bottom: -140px;
        width: 190px;
      }
    }
  }
}
@media screen and (max-width: 1499px){
  .main-second-screen{
    .container-h{
      .figure-2{
        width: 160px;
      }
    }
  }
}
@media screen and (max-width: 1399px){
  .main-second-screen{
    h2{
      font-size: 70px;
      line-height: 90px;
      padding: 0 30px;
    }
    .container-h{
      .figure-1{
        width: 190px;
      }
      .figure-2{
        width: 140px;
        bottom: -90px;
      }
    }
  }
}

@media screen and (max-width: 1199px){
  .main-second-screen{
    h2{
      font-size: 60px;
      line-height: 70px;
    }
    h3{
      font-size: 36px;
      line-height: 46px;
      margin-bottom: 20px;
    }
    p{
      font-size: 24px;
      line-height: 30px;
    }
    .container-h{
      .figure-1{
        width: 190px;
      }
      .figure-2{
        width: 110px;
        bottom: -70px;
      }
    }
  }
}

@media screen and (max-width: 991px){
  .main-second-screen{
    h2{
      font-size: 46px;
      line-height: 56px;
      padding: 0 0px;
    }
    .container-h{
      .figure-1{
        width: 160px;
      }
      .figure-2{
        width: 110px;
        right: 250px;
        bottom: -100px;
      }
    }
    .block-img{
      position: relative;
      &.img-1{
        margin-top: -120px;
        margin-left: auto;
      }
      &.img-2{
        margin-top: -270px;
        margin-right: auto;
      }
    }
    p.right{
        padding-right: calc(25.77vw + 20px);
    }
    p.left{
      padding-left: calc(27.77vw + 20px);
    }
    h3.right{
      padding-right: 0;
    }
    h3.left{
      padding-left: 0;
    }
  }
}

@media screen and (max-width: 767px){
  .main-second-screen{
    h2{
      font-size: 38px;
      line-height: 48px;
      margin-bottom: 100px;
    }
    .container-h{
      .figure-1{
        width: 120px;
        left:10px;
        bottom: -80px;
      }
    }
    h3{
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 20px;
    }
    p{
      font-size: 20px;
      line-height: 24px;
    }
    .block-img{
      &.img-2{
        margin-top: -260px;
      }
    }
    p.left:last-child{
      margin-top: 5px;
      padding-left: 0;
    }
  }
}

@media screen and (max-width: 575px){
  .main-second-screen{
    padding: 30px 0 25px;
    .block{
      margin-bottom: 30px;
    }
    h2{
      margin-bottom: 40px;
      font-size: 29px;
      line-height: 39px;
    }
    h3{
      font-size: 26px;
      line-height: 30px;
      margin-bottom: 20px;
    }
    p.right{
      padding-right: 0;
    }
    p.left{
      padding-left: 0;
    }
    p{
      font-size: 16px;
      line-height: 20px;
    }
    .block-img{
      margin-top: 20px;
      &.img-2{
        width: 100%;
        margin-top: 20px;
      }
      &.img-1{
        width: 100%;
        margin-top: 20px;
      }
    }
    .container-h{
      .figure-1{
        display: none;
      }
      .figure-2{
        display: none;
      }
    }
  }
}

@media screen and (max-width: 399px){
  .main-second-screen{

  }
}