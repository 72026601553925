.main-first-screen{
  background-color: #151623;
  padding: 70px 0 50px;
  .container-h1{
    margin-top: 70px;
    position: relative;
    h1{
      text-align: center;
      font-size: 85px;
      line-height: 115px;
      font-weight: 700;
      color: #fff;
      position: relative;
      z-index: 5;
    }
    .figure-1{
      position: absolute;
      z-index: 1;
      top: -35px;
      left: 30px;
      .figure-block{
        position: relative;
        display: flex;
        align-items: center;
        .square{
          background-color: #73737b;
          width: 200px;
          height: 70px;
          margin-right: 50px;
        }
        .figure-text{
          position: relative;
          width: 175px;
          .icon-text{
            position: absolute;
            width: 20px;
            height: 20px;
            left:-30px;
            img{
              width: inherit;
              height: inherit;
              object-fit: contain;
              object-position: center;
              display: block;
            }
          }
          .text{
            font-size: 16px;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.5);
          }
        }

      }

    }
    .figure-2{
      position: absolute;
      z-index: 1;
      top: -70px;
      right: 35px;
      .figure-block{
        position: relative;
        display: flex;
        .square{
          background-color: #6666ff;
          width: 70px;
          height: 140px;
          margin-right: 20px;

        }
        .figure-text{
          width: 150px;
          .icon-text{
            margin-bottom: 5px;
            img{
              width: inherit;
              height: inherit;
              object-fit: contain;
              object-position: center;
              display: block;
            }
          }
          .text{
            font-size: 16px;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.5);
          }
        }

      }

    }
  }
  .first-text{
    margin-top: 70px;
    position: relative;
    margin-bottom: 70px;
    .figure-3{
      z-index: 1;
      position: absolute;
      left: 30px;
      top:-10px;
      .figure-block{
        position: relative;
        display: flex;
        .square{
          background-color: #b23d6d;
          width: 200px;
          height: 90px;

        }
        .figure-text{
          width: 130px;
          position: relative;
          margin-right: 20px;
          .icon-text{
            position: absolute;
            width: 20px;
            height: 20px;
            right: 10px;
            bottom: 10px;
            img{
              width: inherit;
              height: inherit;
              object-fit: contain;
              object-position: center;
              display: block;
            }
          }
          .text{
            font-size: 16px;
            text-align: left;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.5);
          }
        }

      }

    }
    .text{
      position: relative;
      z-index: 5;
      font-size: 45px;
      line-height: 60px;
      color: #fff;
      text-align: center;
    }
  }
  .container-icons{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    border-top: #666666 2px solid;
    border-bottom: #666666 2px solid;
    padding: 50px 0;
    .icon{
      width: 40px;
      height: 40px;
      margin: 0 15px;
      img{
        display: block;
        width: inherit;
        height: inherit;
        object-fit: contain;
        object-position: center;

      }
    }
  }
  overflow: hidden;


}

@media screen and (max-width: 1649px){
  .main-first-screen{

  }
}
@media screen and (max-width: 1399px){
  .main-first-screen{
    .container-h1{
      h1{
        font-size: 70px;
        line-height: 90px;
      }
    }
    .first-text{
      .text{
        font-size: 40px;
        line-height: 50px;
      }
      .figure-3{
        top: -15px;
        .figure-block{
          .square{

          }
          .figure-text{
            margin-right: 0px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .main-first-screen {
    .container-h1 {
      h1 {
        font-size: 60px;
        line-height: 70px;
      }
      .figure-1{
        .figure-block{
          .square{
            width: 120px;
            height: 60px;
            margin-right: 30px;
          }
          .figure-text{
            .icon-text{
              width: 14px;
              height: 14px;
              left: -20px;
            }
            .text{
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
      .figure-2{
        right: 0;
        .figure-block{
          .square{
            width: 60px;
            height: 120px;
          }
          .figure-text{
            .icon-text{
              width: 14px;
              height: 14px;
              left: 0px;
            }
            .text{
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
    }
    .first-text{
      margin-top: 50px;
      margin-bottom: 50px;
      .text{
        font-size: 30px;
        line-height: 40px;
      }
      .figure-3{
        top: -10px;
        .figure-block{
          .square{
            height: 64px;
            width: 140px;
          }
          .figure-text{
            margin-right: 0px;
            .icon-text{
              width: 14px;
              height: 14px;
              right: 20px;
              bottom: 0px;
            }
            .text{
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px){
  .main-first-screen{
    .container-h1{
      h1{
        font-size: 46px;
        line-height: 56px;
      }
      .figure-1{
        top: -45px;
      }
    }
    .first-text{
      .text{
        font-size: 20px;
        line-height: 24px;
      }
      .figure-3{
        top: -20px;
        left: 0;
      }
    }
    .container-icons{
      padding: 30px 0 15px;
      flex-wrap: wrap;
      .icon{
        margin-bottom: 15px;
      }
    }
  }
}

@media screen and (max-width: 767px){
  .main-first-screen{
    padding: 0px 0 20px;
      .container-h1{
        h1{
          font-size: 38px;
          line-height: 48px;
        }
        .figure-1{
          top: -40px;
          left: -12px;
          .figure-block{
            .square{
              width: 90px;
              height: 50px;
            }
            .figure-text{
              .text{
                font-size: 12px;
                line-height: 14px;
              }
            }
          }
        }
        .figure-2{
          right: -50px;
          top: -50px;
          .figure-block{
            .square{
              width: 50px;
              height:90px;
            }
            .figure-text{
              .text{
                font-size: 12px;
                line-height: 14px;
              }
            }
          }
        }
      }

      .first-text{
        .text{
          font-size: 16px;
          line-height: 20px;
        }
        .figure-3{
          top: -18px;
          left: -10px;
          .figure-block{
            .square{
              height: 56px;
              width: 92px;
            }
            .figure-text{
              width: 100px;
              .icon-text{
                right: 12px;
              }
              .text{
                font-size: 12px;
                line-height: 14px;
              }

            }
          }
        }
    }
  }
}

@media screen and (max-width: 575px){
  .main-first-screen{
    .container-h1{
      h1{
        font-size: 29px;
        line-height: 39px;
      }
      .figure-1{
        top: -40px;
        left: -12px;
        .figure-block{
          .square{
            width: 60px;
            height: 40px;
            margin-right: 15px;
          }
          .figure-text{
            .icon-text{
              width: 10px;
              height: 10px;
              left: -12px;
            }
            .text{
              font-size: 9px;
              line-height: 12px;
            }
          }
        }
      }
      .figure-2{
        right: -70px;
        top: -50px;
        .figure-block{
          .square{
            width: 40px;
            height:70px;
            margin-right: 15px;
          }
          .figure-text{
            .icon-text{
              width: 10px;
              height: 10px;
            }
            .text{
              font-size: 9px;
              line-height: 12px;
            }
          }
        }
      }
    }
    .first-text{
      .figure-3{
        top: -30px;
        left: 0;
        .figure-block{
          .square{
            height: 50px;
            width: 80px;
          }
          .figure-text{
            width: 130px;
            .icon-text{
              width: 10px;
              height: 10px;
              bottom: 25px;
            }
            .text{
              font-size: 9px;
              line-height: 12px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 399px){
  .main-first-screen{

  }
}