footer{
  background-color: #151623;
  margin-top: auto;
  .top-footer{
    padding: 100px 0px 50px;
    border-top: 1px solid #D5D3D4;
    border-bottom: 1px solid #D5D3D4;
    .footer-top-text{
      padding-left: 50px;
      margin-bottom: 50px;
      line-height: 46px;
      font-size: 30px;
      color: #fff;
      .big{
        text-transform: uppercase;
      }
    }
    .list{
      margin-left: -2vw;
    }
    .col-auto{
      width: 14.28vw;
      .logo-dev{
        width: inherit;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          display: block;
          height: 60px;
          width: auto;
          object-fit: contain;
          object-position: center;
        }
        &.size-60{
          img{
            height: 60px;
          }
        }
        &.size-40{
          img{
            height: 40px;
          }
        }
        &.size-30{
          img{
            height: 30px;
          }
        }
      }
    }
  }
  .bottom-footer{
    padding: 40px 50px;
    .logo-copyright{
      display: flex;
      .logo{
        height: 57px;
        margin-right: 20px;
        img{
          display: block;
          height: inherit;
          object-fit: contain;
          object-position: center;
        }
      }
      .copyright{
        font-size: 18px;
        line-height: 30px;
        color: #fff;
      }
    }
    .politics{
      font-size: 18px;
      line-height: 30px;
      color: #fff;
    }

  }
}

@media screen and (max-width: 1649px){
  footer{
    .top-footer{
      .list{
        margin-left: -1vw;
      }
      .col-auto{
        width: 14.10vw;
      }
    }
    .bottom-footer{

    }
  }
}
@media screen and (max-width: 1499px){
  footer{
    .top-footer{
      .footer-top-text{
        padding-left: 0;
      }
      .list{
        margin-left: 0;
      }
      .col-auto{
        width: 20%;
        .logo-dev{
          width: 100%;
        }
      }
    }
    .bottom-footer{
      padding: 40px 0px;
    }
  }
}
@media screen and (max-width: 1399px){
  footer{

  }
}
@media screen and (max-width: 1199px){
  footer{
    .top-footer{
      .footer-top-text{
        font-size: 26px;
        line-height: 34px;
      }
      .col-auto{
        width: 20%;
        .logo-dev{
          width: 100%;
          &.size-60{
            img{
              height: 40px;
            }
          }
          &.size-40{
            img{
              height: 30px;
            }
          }
          &.size-30{
            img{
              height: 25px;
            }
          }
        }
      }
    }
    .bottom-footer{
      padding: 30px 0px;
      .logo-copyright{
        .logo{
          height: 40px;
        }
        .copyright{
          font-size: 14px;
          line-height: 20px;
        }
      }
      .politics{
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

@media screen and (max-width: 991px){
  footer{
    .top-footer{
      padding: 50px 0px 25px;
      .footer-top-text{
        text-align: center;
      }
      .col-auto{
        width: 25%;
        .logo-dev{
          &.size-60{

          }
          &.size-40{

          }
          &.size-30{

          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px){
  footer{
    .top-footer{
      .footer-top-text{
        font-size: 22px;
        line-height: 26px;
      }
      .col-auto{
        width: 33.3333%;
        .logo-dev{
          &.size-60{

          }
          &.size-40{

          }
          &.size-30{

          }
        }
      }
    }
    .bottom-footer{
      padding: 30px 0px;
      .logo-copyright{
        .logo{
          height: 30px;
        }
        .copyright{
          font-size: 12px;
          line-height: 16px;
        }
      }
      .politics{
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
@media screen and (max-width: 575px){
  footer{
    .top-footer{
      .footer-top-text{
        font-size: 16px;
        line-height: 22px;
      }
      .col-auto{
        width: 50%;
        .logo-dev{
          &.size-60{

          }
          &.size-40{

          }
          &.size-30{

          }
        }
      }
    }
    .bottom-footer{
      padding: 30px 0px;
      .logo-copyright{
        margin-bottom: 20px;
        .logo{
        }
        .copyright{
          text-align: center;
        }
      }
      .politics{
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        display: block;
      }
    }
  }
}
@media screen and (max-width: 399px){
  footer{
    .top-footer{
      .footer-top-text{
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 30px;
      }
      .col-auto{
        width: 50%;
        .logo-dev{
          margin-bottom: 20px;
          &.size-60{
            img{
              height: 30px;
            }
          }
          &.size-40{
            img{
              height: 25px;
            }
          }
          &.size-30{
            img{
              height: 20px;
            }
          }
        }
      }
    }
    .bottom-footer{
      padding: 30px 0px;
      .logo-copyright{
        margin-bottom: 20px;
        .logo{
          margin-right: 10px;
        }
        .copyright{
          font-size: 10px;
          line-height: 14px;
        }
      }
      .politics{
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
}