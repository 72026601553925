.parametr-section{
  .list-props{
    width: 350px;
    padding-left: 20px;
    ul{
      li{
        margin-bottom: 10px;
        .text{
          color: #848484;
          font-size: 16px;
          line-height: 19px;
        }
        &.parent{
          position: relative;
          cursor: pointer;
          margin-bottom: 10px;
          &>.text{
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #333333;
          }

          .child-list{
            display: none;
            padding: 10px 0 10px 20px;
            ul{
              li{
                margin-bottom: 0px;
              }
            }
          }
          &.parent-open{
            .child-list{
              display: block;
            }
          }
        }
        &:hover{
          &>.text{
            color: #6f42c1;
          }
        }
      }
    }
    .list-control{
      margin-top: 40px;
      .btn{
        height: 45px;
        font-size: 16px;
        line-height: 30px;
        font-weight: 500;
        width: calc(100% - 15px);
      }
    }
  }
  .parametr-block{
    border: 1px solid #E2E8F0;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    .name-block{
      padding-bottom: 15px;
      margin-bottom: 20px;
      border-bottom: 1px solid #E5E5E5;
      color: #211F35;
      font-size: 20px;
      line-height: 1;
      font-weight: 700;
    }
    .fields-block{
      margin-bottom: 30px;
      .container-fields{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .field-label{
          font-size: 14px;
          line-height: 1;
          text-align: right;
          margin-right: 15px;
          width: 20%;
          max-width: 175px;
        }
        .field-value{
          margin-right: 15px;
          background-color: #FBF9FD;
          border:1px solid #DDDEE3;
          border-radius: 10px;
          font-size: 14px;
          line-height: 1;
          padding: 10px;
          color: #262626;
          width: 66.6666%;
          &.field-size-small{
            width: 26.8%;
          }

        }
        .field-status{
          margin-left: auto;
          width: 13%;
          text-align: center;
          border-radius: 100px;
          font-size: 12px;
          color: #000000;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.field-status-new{
            background-color: #e5e5e5;
          }
          &.field-status-not{
            background-color: #F5D4E2;
          }
          &.field-status-agreed{
            background-color: #C4FAA4;
          }
          &.field-status-work{
            background-color: #CCCCFC;
          }
        }
        .field-correl{
          margin-right: 15px;
          width: 25px;
          img{
            display: block;
            width: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }
    }
    .categories-block{
      display: flex;
      .container-categories{
        width: 80%;
        .categories-name{
          display: flex;
          justify-content: space-between;
          border-bottom:1px solid #727CF5;
          align-items: center;
          .name{
            color: #09A1DF;
            font-size: 14px;
            line-height: 1;
            text-transform: uppercase;
            font-weight: 700;
            margin-right: 15px;
          }
          .icon{
            width: 24px;
            margin-bottom: 2px;
            img{
              display: block;
              width: 100%;
              object-fit: contain;
              object-position: center;


            }
          }
        }
        .categories-selected{
          display: flex;
          flex-wrap: wrap;
          flex-shrink: 0;
          margin: 10px -5px 30px;
          .category-selected{
            background-color: #EEF2F7;
            border-radius: 100px;
            margin: 10px 5px;
            padding: 12px 40px 12px 15px;
            font-weight: 600;
            font-size: 14px;
            color: #6D767E;
            line-height: 1;
            position: relative;
            &::before{
              content: '';
              display: block;
              width: 2px;
              height: 12px;
              position: absolute;
              right: 20px;
              background-color: #C4C4C4;
              top: calc(50% - 6px);
              transform: rotate(45deg);
            }
            &::after{
              content: '';
              display: block;
              width: 2px;
              height: 12px;
              position: absolute;
              right: 20px;
              background-color: #C4C4C4;
              top: calc(50% - 6px);
              transform: rotate(-45deg);
            }
          }
        }
      }
      .categories-status{
        margin-left: auto;
        width: 13%;
        text-align: center;
        border-radius: 100px;
        font-size: 12px;
        color: #000000;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.categories-status-new{
          background-color: #e5e5e5;
        }
        &.categories-status-not{
          background-color: #F5D4E2;
        }
        &.categories-status-agreed{
          background-color: #C4FAA4;
        }
        &.categories-status-work{
          background-color: #CCCCFC;
        }
      }
    }
    .table-block{
      .table-name{
        color: #09A1DF;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        font-weight: 700;
        border-bottom: 1px solid #727CF5;
        padding-bottom: 5px;
        margin-bottom: 10px;
      }
      table{
        width: 100%;
        thead{
          tr{
            border-bottom: 1px solid #C4C4C4;
          }
        }
        tr{
          th{
            padding: 5px 5px 15px;
            &:nth-child(3){
              width: 14%;
              text-align: center;
            }
            &:nth-child(2){
              text-align: center;
            }
          }
          td{
            &:nth-child(2){
              text-align: center;
            }
            padding: 10px;
            .content-td{
              color: #000000;
              font-size: 14px;
              line-height: 16px;
              .status{
                text-align: center;
                border-radius: 100px;
                font-size: 12px;
                color: #000000;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                &.status-new{
                  background-color: #e5e5e5;
                }
                &.status-not{
                  background-color: #F5D4E2;
                }
                &.status-agreed{
                  background-color: #C4FAA4;
                }
                &.status-work{
                  background-color: #CCCCFC;
                }
              }
            }
          }
        }
      }
    }
  }
}