body{
  background-color: #FBF9FD;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  h1{
    color: #383836;
    font-size: 60px;
    line-height: 85px;
    margin-bottom: 40px;
    font-weight: 700;
  }
  .wrapper{
    margin-top: 100px;
    padding:50px 0;
  }
  *{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    position: relative;
  }
  ul,ol{
    list-style: none;
    margin: 0;
    padding: 0;
  }
  a{
    color: #333;
    text-decoration: none;
  }
  input {outline:none;}

  &.open-mobile-menu{
    overflow: hidden;
    header{
      border-bottom: 1px solid #212831;
        .btn-mobile-menu{
          border-color: #6666FF;
          background-color: #6666FF;
          .text-menu{
            color: #fff;
          }
          .icon-menu{
            .icon-block{
              &:nth-child(2) {
                opacity: 0;
                margin: 0;
              }
              &:nth-child(1){
                position: relative;
                top:2px;
                transform-origin: 50% 50%;
                transform: rotate(-225deg);
                background-color: #fff;
              }
              &:nth-child(3){
                position: relative;
                top:-2px;
                transform-origin: 50% 50%;
                transform: rotate(225deg);
                background-color: #fff;
              }
            }
          }
        }
      .container-mobile-menu{
        left: 0;
        .mobile-menu{
          &::before{
            opacity: 1;
            transition: opacity ease .5s .5s;

          }
        }
      }

    }
  }
  &.main-page{
    .wrapper{
      padding: 0 0px;
    }
  }
}
.btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 247px;
  height: 55px;
  border-radius: 100px;
  background-color: #6666ff;
  font-weight: 500;
  font-size: 24px;
  color: #fff;
  &:hover{
    background-color: #6666ff;
    color: #fff;
  }
}
@media screen and (max-width: 1199px){
  .btn{
    width: 200px;
    height: 45px;
    font-size: 20px;
  }
  body{
    h1{
      color: #383836;
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 40px;
      font-weight: 700;
    }
    .wrapper{
      margin-top: 80px;
    }
  }
}
@media screen and (max-width: 991px){
  body{
    h1{
      color: #383836;
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 20px;
    }
    .wrapper{
      margin-top: 60px;
    }
  }
}
@media screen and (max-width: 575px){
  .btn{
    width: 100%;
    height: 45px;
    font-size: 20px;
  }
  body{
    h1{
      color: #383836;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 20px;
    }
    .wrapper{
      margin-top: 50px;
    }
  }

}

@import "header.scss";
@import "footer.scss";
@import "breadcrumbs.scss";

//Главная страница

@import "pages/main-page/main-first-screen";
@import "pages/main-page/main-second-screen";
@import "pages/main-page/main-third-screen";

//Cтраница публикации

@import "pages/publications-page/publications-page";

//Cтраница с таблицей

@import "pages/theme-page-1/table-section";
@import "pages/theme-page-1/parametr-section";


@import "pages/parametrs-page/parametrs-page";
