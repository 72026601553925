.main-third-screen{
  background-color: #151623;
  padding-bottom: 100px;
  overflow: hidden;
  h2{
    font-size: 85px;
    line-height: 115px;
    text-align: center;
    color: #fff;
    margin-bottom: 60px;
    span{
      color: #6666FF;
    }
  }
  .block-text{
    position: relative;
    margin-bottom: 60px;
    .figure-1{
      position: absolute;
      z-index: 1;
      right: -100px;
      bottom: -140px;
      width: 85px;

      img{
        width: 100%;
        display: block;
      }

    }
    p{
      position: relative;
      z-index: 5;
      font-size: 30px;
      line-height: 40px;
      color: #73737b;
    }
  }
  .list{
    margin-bottom: 40px;
    li{
      font-size: 48px;
      text-align: center;
      line-height: 60px;
      color: #fff;
    }
  }
  .figure-2{
    width: 45.44vw;
    margin-bottom: 90px;
    img{
      display: block;
      width: inherit;
      object-fit: contain;
    }
  }
  .btn{
    margin-bottom: 100px;
  }
  .figure-3{
    width: 24.47vw;
    margin-left: 50px;
    img{
      display: block;
      width: inherit;
      object-fit: contain;
    }
  }
  .big{
    font-size: 48px;
    line-height: 65px;
    color: #fff;
    width: 580px;
    margin-left: 50px;
  }
}

@media screen and (max-width: 1649px){
  .main-third-screen{
    .block-text{
      p{
        font-size: 28px;
        line-height: 38px;
      }
    }
  }
}
@media screen and (max-width: 1399px){
  .main-third-screen{
    h2{
      font-size: 70px;
      line-height: 90px;
    }
    .list{
      li{
        font-size: 36px;
        line-height: 46px;
      }
    }
    .big{
      font-size: 36px;
      line-height: 46px;
    }
  }
}

@media screen and (max-width: 1199px){
  .main-third-screen{
    h2{
      font-size: 60px;
      line-height: 70px;
    }
    .block-text{
      p{
        font-size: 24px;
        line-height: 30px;
      }
    }
    .list{
      li{
        font-size: 32px;
        line-height: 36px;
      }
    }
    .big{
      font-size: 32px;
      line-height: 36px;
      margin-left: 0;
    }
    .figure-3{
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 991px){
  .main-third-screen{
    h2{
      font-size: 46px;
      line-height: 56px;
    }
    .block-text{
      .figure-1{
        bottom: -120px;
        width: 70px;
      }
    }
    .figure-2{
      width: 80.44vw;
      margin-bottom: 40px;
    }
    .big{
      font-size: 32px;
      line-height: 36px;
      margin-left: 0;
      width: 100%;
    }
    .figure-3{
      margin-left: 0;
      width: 100%;
      img{
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 767px){
  .main-third-screen{
    h2{
      font-size: 38px;
      line-height: 48px;
    }
    .block-text{
      margin-bottom: 30px;
      p{
        font-size: 20px;
        line-height: 24px;
      }
      .figure-1{
        right: -73px;
        bottom: -120px;
        width: 60px;
      }
    }
    .list{
      li{
        font-size: 26px;
        line-height: 30px;
      }
    }
    .big{
      font-size: 25px;
      line-height: 29px;
    }
  }
}

@media screen and (max-width: 575px){
  .main-third-screen{
    h2{
      font-size: 29px;
      line-height: 39px;
      margin-bottom: 40px;
    }
    .block-text{
      margin-bottom: 20px;
      p{
        font-size: 16px;
        line-height: 20px;
        br{
          display: none;
        }
      }
      .figure-1{
        display: none;
      }
    }
    .list{
      li{
        font-size: 20px;
        line-height:24px;
      }
    }
    .btn{
      margin-bottom: 30px;
    }
    .big{
      margin-top: 20px;
      font-size: 20px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 399px){
  .main-third-screen{

  }
}