header{
    background-color: #212831;
    position: fixed;
    z-index: 100;
    height: 100px;
    width: 100%;
    border-bottom:1px solid  #909498;
    .container-fluid{
        margin: 0 5vw;
        width: calc(100% - 10vw);
        height: 100%;
        .row{
            height: inherit;
        }
    }
    .logo{
        width: 123px;
        height: 64px;
        display: block;
        img{
            width: inherit;
            height: inherit;
            object-fit: contain;
            object-position: center;
            display: block;
        }
    }
    .top-menu{
        height: 100px;
        ul{
            height: inherit;
            display: flex;
            align-items: center;
            li{
                height: inherit;
                a{
                    padding: 0 3.2vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: inherit;
                    color: #fff;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 1;
                    text-transform: uppercase;
                    transition: ease .4s;
                    &:hover{
                        background-color: #6666FF;
                    }
                }
            }
        }
    }
    .btn-mobile-menu{
        cursor: pointer;
        height: 60px;
        transition: ease .4s;
        display: flex;
        align-items: center;
        padding: 0 12px 0 24px;
        margin-right: -12px;
        .text-menu{
            line-height: 1;
            font-size: 12px;
            color: #909498;
            font-weight: 700;
            margin: 0 12px 0 0;
            transition: ease .4s;
        }
        .icon-menu{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .icon-block{
                width: 20px;
                height: 2px;
                background-color: #909498;
                transition: ease .4s;
                &:nth-child(2){
                    margin: 4px 0;
                }
            }
        }
        &:hover{
            border-color: #6666FF;
            .text-menu{
                color: #6666FF;
            }
            .icon-menu{
                .icon-block{
                    background-color: #6666FF;
                }
            }
        }

    }
    .container-mobile-menu{
        z-index: 900;
        width: 100vw;
        height: calc(100dvh - 49px);
        top:0px;
        left: 200vw;
        transition: left ease .4s;
        .mobile-menu{
            position: relative;
            width: inherit;
            height: inherit;
            .menu{
                background-color: #212831;
                width: 260px;
                height: 100%;
                position: absolute;
                top: 1px;
                right: 0;
                .hidden-block{
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    .scroll-block{
                        width: calc(100% + 50px);
                        height: calc(100% - 70px);
                        overflow:auto;
                        .lists{
                            .list{
                                margin-bottom: 24px;
                                .content-list{
                                    ul{
                                        li{
                                            a{
                                                display: block;
                                                font-size: 14px;
                                                color: #909498;
                                                transition: ease .4s;
                                                font-weight: 500;
                                                text-transform: uppercase;
                                                padding: 15px 24px;
                                                &:hover{
                                                    color: #fff;
                                                    background-color: #6666ff;
                                                }
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    }
                    .control-block{
                        height: 60px;
                        position: relative;
                        display: flex;
                        border-top: 1px solid #909498;
                        .btn{
                            padding: 0;
                            display: flex;
                            width: 50%;
                            height: inherit;
                            background-color: transparent;
                            color:#909498;
                            font-weight: 700;
                            text-transform: uppercase;
                            transition: ease .4s;
                            font-size: 12px;
                            border-radius: 0;
                            align-items: center;
                            justify-content: center;
                            &:hover{
                                background-color: #6666ff;
                                color: #fff;
                            }


                        }
                        &::before{
                            content: '';
                            position: absolute;
                            display: block;
                            width: 1px;
                            height: 25px;
                            background-color: #909498;
                            top: calc(50% - 12.5px);
                            left: 50%;
                            transition: ease .4s;
                            opacity: 1;
                        }
                        &:hover{
                            &::before{
                                opacity: 0;
                            }
                        }
                    }
                }

            }
            &::before{
                content: '';
                background-color: rgba(39, 41, 45, 0.5);
                width: calc(100vw - 260px);
                position: absolute;
                left: 0;
                opacity: 0;
                height: 100%;
            }
        }

    }
}

@media screen and (max-width: 1649px){
    header{

    }
}
@media screen and (max-width: 1499px){
    header{
        .container-fluid{
            width: 100%;
            margin: 0;
        }
        .top-menu{
            ul{
                li{
                    a{
                        padding: 0 2.5vw;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1399px){
    header{

    }
}
@media screen and (max-width: 1199px){
    header{
        height: 80px;
        .logo{
            width: 80px;
            height: 40px;
        }
        .top-menu{
            height: 80px;
            ul{
                li{
                    a{
                        padding: 0 2vw;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 991px){
    header{
        height: 60px;
        .logo{
            width: 80px;
            height: 40px;
        }
        .top-menu{
            display: none;
        }
    }
}
@media screen and (max-width: 767px){
    header{
        .logo{
            width: 70px;
            height: 35px;
        }

    }
}
@media screen and (max-width: 575px){
    header{
        height: 50px;
        .logo{
            width: 50px;
            height: 25px;
        }
        .container-mobile-menu {
            z-index: 900;
            width: 100vw;
            height: calc(100dvh - 49px);
            .mobile-menu{
                .menu{
                    .hidden-block{
                        .scroll-block{
                            height: calc(100% - 51px);
                        }
                        .control-block{
                            height: 50px;
                        }
                    }
                }
            }
        }
        .btn-mobile-menu{
            height: 50px;
        }

    }
}
@media screen and (max-width: 399px){
    header {
    }

}