.parametrs-page{
  .text-block{
    color: #383836;
    font-size: 24px;
    line-height: 36px;
  }
  h2{
    color: #383836;
    font-size: 45px;
    line-height: 52px;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .table-block{
    width: 100%;
    overflow: auto;
    border: 1px solid #E2E8F0;
    box-shadow: 0 0 10px 0 rgba(0,0,0, .1);
    padding: 20px;
    background-color: #fff;
    table{
      tr{
        th{
          .content-th{
            padding: 0 10px 10px 10px;
            .text{
              font-weight: 500;
              font-size: 16px;
              color: #383836;
            }
          }
          &:nth-child(1){
            width: 25%;
            min-width: 200px;
          }
          &:nth-child(2){
            width: 25%;
            min-width: 200px;
          }
          &:nth-child(3){
            width: 40%;
            min-width: 200px;
          }
          &:nth-child(4){
            width: 100px;
            min-width: 100%;
          }
        }
      }
      tbody{
        tr{
          &:nth-child(odd){
            background-color: #FAFAFA;
          }
          td{
            padding: 0;
            .content-td{
              padding: 10px;
              .text{
                font-size: 16px;
                line-height: 25px;
                color: #383836;
              }
              .container-dev{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin: 0 -10px;
                .dev-logo{
                  margin: 10px;

                  img{
                    display: block;
                    height: 30px;
                    object-fit: contain;
                    object-position: center;
                  }
                }
              }
              .btn{
                width: 97px;
                height: 27px;
                font-size: 14px;
                line-height: 16px;border-radius: 5px;
              }
            }
          }

        }
      }
    }
  }
}

@media screen and (max-width: 1199px){
  .parametrs-page{
    .text-block{
      font-size: 20px;
      line-height: 24px;
    }
    h2{
      font-size: 30px;
      line-height: 40px;
    }
  }
}
@media screen and (max-width: 767px){
  .parametrs-page{
    .table-block{
      table{
        tr{
          th {
            .content-th {
              padding: 10px 10px 10px 10px;
              .text {
                font-weight: 500;
                font-size: 14px;
                color: #383836;
              }
            }
          }
        }
        tbody{
          tr{
            td{
              .content-td{
                .text{
                  font-size: 14px;
                }
                .container-dev{
                  .dev-logo{
                    img{
                      height: 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px){
  .parametrs-page{
    .text-block{
      font-size: 16px;
      line-height: 20px;
    }
    h2{
      font-size: 26px;
      line-height: 30px;
    }
  }
}